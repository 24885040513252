import React from 'react';
import styled from '@emotion/styled/macro';
import { colors } from '@utils/ui_palette';

interface IRoadPoint {
    color: string;
    title: string;
    en_title: string;
    years: number;
    isMargin: boolean;
    isYear: boolean;
}

function RoadPoint({
    color, title, en_title, years, isMargin, isYear,
}: IRoadPoint) {
    return (
        <RoadPointBox>
            <Point
                color={color}
                margin={isMargin}
                isYear={isYear}
                years={`${years}`}
            />
            <Title>{title}</Title>
            <EnTitle>
                (
                {en_title}
                )
            </EnTitle>
        </RoadPointBox>
    );
}

const RoadPointBox = styled.div`
    position: relative;
    top: -17px;
`;

const Point = styled.div<{color: string, margin: boolean, isYear: boolean, years: string}>`
    width: 40px;
    height: 40px;
    background-color: ${(props) => (props.color)};
    border-radius: 50%;
    border: 3px solid ${colors.White100};
    position: relative;
    z-index: 3;

    &:before {
        display: ${(props) => (props.isYear ? 'block' : 'none')};
        font-size: 24px;
        color: ${(props) => (props.color)};
        height: 90px;
        width: 70px;
        border-left: 1px solid ${(props) => (props.color)};
        padding-left: 11px;
        position: absolute;
        bottom: calc(100% - 14px);
        left: ${(props) => (props.margin ? '-35px' : '-2px')};
        z-index: 1;
        content: ${(props) => (`'${props.years}'`)};
    }
`;

const Title = styled.div`
    color: ${colors.Black100};
    font-size: 28px;
    font-weight: 700;
    margin-top: 20px;
`;

const EnTitle = styled.div`
    color: ${colors.Black100};
    font-size: 22px;
    margin-top: 2px;
`;

export default RoadPoint;
