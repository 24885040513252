import React, { useEffect } from 'react';
import { RecoilRoot } from 'recoil';
import { Route, Routes } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import Layouts from '@components/layout/Layouts';
import Home from '@pages/Home';

declare global {
    interface Window {
        IMP : any;
    }
}

function App() {
    useEffect(() => {
        document.documentElement.scrollTo(0, 0);
    // eslint-disable-next-line no-restricted-globals
    }, [location.pathname]);

    useEffect(() => {
        if (isMobile && window.location.hostname !== 'localhost') {
            window.location.assign(`//m.${window.location.host}`);
        }
    }, []);

    return (
        <RecoilRoot>
            <Routes>
                <Route element={<Layouts />}>
                    <Route
                        path="/"
                        element={<Home />}
                    />
                </Route>
            </Routes>

        </RecoilRoot>
    );
}

export default App;
