import React, { useMemo } from 'react';
import styled from '@emotion/styled/macro';
import { useNavigate, useLocation } from 'react-router-dom';
import { colors } from '@utils/ui_palette';
import { rgba } from 'emotion-rgba';
import { transparentHeader } from '@utils/help';
import useScroll from '@utils/useScroll';

function Header() {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    // 스크롤 Y 값
    const { y } = useScroll();

    // 로고 링크
    const handleLogo = () => navigate('/');

    const getHeaderType = useMemo(() => {
        if (transparentHeader(pathname) && y < 200) {
            return rgba(colors.White100, 0.7);
        }

        if (transparentHeader(pathname) && y < 200) {
            return rgba(colors.White100, 0.7);
        }

        return rgba(colors.White100, 0.7);
    }, [pathname, y]);

    return (
        <Headers
            type={getHeaderType}
            isMain={transparentHeader(pathname)}
            yScroll={y}
        >
            <Container>
                {/* 로고 */}
                <Logo>
                    <LogoImage
                        type={getHeaderType}
                        onClick={handleLogo}
                    />
                </Logo>

                {/* SNS */}
                <SnsBox>
                    <SnsItems>
                        <SnsImage
                            url="/img/layout/ic_facebook.svg"
                            hover="/img/layout/ic_facebook_hover.svg"
                            onClick={() => window.open('https://www.facebook.com/profile.php?id=100094474236045')}
                        />
                    </SnsItems>
                    <SnsItems>
                        <SnsImage
                            url="/img/layout/ic_instagram.svg"
                            hover="/img/layout/ic_instagram_hover.svg"
                            onClick={() => window.open('https://www.instagram.com/podoland_official/')}
                        />
                    </SnsItems>
                </SnsBox>
            </Container>
        </Headers>
    );
}

const Container = styled.div`
    width: 1380px;
    padding: 0 30px;
    height: 80px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10000;
`;

const Headers = styled.header<{type: string, isMain: boolean, yScroll: number}>`
    width: 100%;
    height: 80px;
    position: fixed;
    backdrop-filter: blur(16px);
    background-color: ${(props) => (props.type)};
    box-shadow: ${(props) => (props.isMain && props.yScroll < 200 ? '0' : `0px 4px 5px 0px ${rgba(colors.Black100, 0.05)}`)};
    border-bottom: ${(props) => (props.isMain && props.yScroll < 200 ? '0' : `1px solid ${rgba(colors.White100, 0.2)}`)};
    left: 0;
    top: 0;
    z-index: 10000;
`;

const Logo = styled.div`
    flex-basis: 185px;
    width: 185px;
    position: relative;
`;

const LogoImage = styled.div<{type: string}>`
    cursor: pointer;
    background-image: url('/img/layout/ic_brand_logo.png');
    background-repeat: no-repeat;
    background-position: center center;
    width: 185px;
    height: 40px;
`;

const SnsBox = styled.div`
    display: flex;
    align-items: center;
`;

const SnsItems = styled.div`
    margin-left: 20px;
    cursor: pointer;
`;

const SnsImage = styled.div<{url: string, hover}>`
    width: 40px;
    height: 40px;
    background: url(${(props) => (props.url)});
    cursor: pointer;

    &:hover {
        background: url(${(props) => (props.hover)});
    }
`;

export default Header;
