import React, { useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { colors } from '@utils/ui_palette';

import AOS from 'aos';
import 'aos/dist/aos.css';

// Components
import HomeTitle from '@components/home/component/HomeTitle';

function Partners() {
    useEffect(() => {
        AOS.init();
    });

    return (
        <Container>
            <TitleBox>
                <AnimationBox
                    data-aos="fade-up"
                    data-aos-duration="3000"
                    data-aos-delay="500"
                >
                    <HomeTitle title="Parthners" />

                    <SectionTitle>
                        포도랜드와
                        {' '}
                        <strong>함께</strong>
                        합니다.
                    </SectionTitle>
                </AnimationBox>

                <PartnerImageBox
                    data-aos="fade-up"
                    data-aos-duration="3000"
                    data-aos-delay="1500"
                >
                    <PartnerImage src="/img/home/img_podoclub.png" />
                    <PartnerImage src="/img/home/img_podoacademy.png" />
                    <PartnerImage src="/img/home/img_globalpodo.png" />
                </PartnerImageBox>
            </TitleBox>
        </Container>

    );
}

const Container = styled.div`
    width: 1380px;
    padding: 180px 30px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const AnimationBox = styled.div``;

const SectionTitle = styled.div`
    margin-top: 20px;
    font-size: 40px;
    color: ${colors.Black200};
    line-height: 1.5;

    span {
        color: ${colors.Blue100};
        font-weight: 700;
    }
`;

const TitleBox = styled.div`
    flex-basis: 217px;
`;

const PartnerImageBox = styled.div`
    margin-top: 62px;
    display: flex;
    align-items: center;
`;

const PartnerImage = styled('img')`
    height: 140px;
`;

export default Partners;
