import React, { useState } from 'react';
import styled from '@emotion/styled/macro';
import Slider from 'react-slick';
import { nl2br } from '@utils/help';
import { colors } from '@utils/ui_palette';

// Components
import HomeTitle from '@components/home/component/HomeTitle';

interface IService {
    title: string;
    en_title: string;
    description: string;
    type: string;
    index: number;
}

function Services() {
    const [slideInfo, setSlideInfo] = useState<IService>({
        title: '포도아이',
        en_title: 'PODO I',
        description: '재미있고 효과적인\n체계적인 1:1 맞춤 교육 플랫폼',
        type: 'i',
        index: 3,
    });

    const settings = {
        dots: true,
        autoplay: true,
        centerMode: true,
        centerPadding: '60px',
        speed: 1000,
        autoplaySpeed: 3000,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        beforeChange: (current: number, next: number) => {
            const serviceObj = {
                title: '포도아이',
                en_title: 'PODO I',
                description: '재미있고 효과적인\n체계적인 1:1 맞춤 교육 플랫폼',
                type: 'i',
                index: 3,
            };
            const nextPage = current < next || current === 3;
            const prevPage = current > next || current === 0;
            let serviceFilter:IService;

            if (nextPage) {
                serviceFilter = serviceData.find((item) => item.index === current) || serviceObj;

                setSlideInfo(serviceFilter);
            }

            if (prevPage) {
                serviceFilter = serviceData.find((item) => item.index === next - 1) || serviceObj;

                setSlideInfo(serviceFilter);
            }
        },
    };

    const serviceData:IService[] = [
        {
            title: '포도시드',
            en_title: 'PODO SEED',
            description: '블록체인 기반의\n크리에이터 인큐베이팅 플랫폼',
            type: 'seed',
            index: 0,
        },
        {
            title: '포도케어',
            en_title: 'PODO CARE',
            description: '가치 있는 경험을 공유하고\n소통하는 건강 플랫폼',
            type: 'health',
            index: 1,
        },
        {
            title: '포도리뉴',
            en_title: 'PODO RENEW',
            description: '일상 속 다양한 생활 자원의 재활용\n디지털 환경 플랫폼',
            type: 'renew',
            index: 2,
        },
        {
            title: '포도아이',
            en_title: 'PODO I',
            description: '재미있고 효과적인\n체계적인 1:1 맞춤 교육 플랫폼',
            type: 'i',
            index: 3,
        },
    ];

    return (
        <SectionBackground>
            <Container>
                <TitleBox>
                    <HomeTitle title="Our Services" />

                    <SectionTitle>
                        <span>{slideInfo.title}</span>
                        <br />
                        {slideInfo.en_title}
                    </SectionTitle>

                    <SectionSubTitle dangerouslySetInnerHTML={{ __html: nl2br(slideInfo.description) }} />

                    <LinkButton>
                        Coming soon
                    </LinkButton>
                </TitleBox>
                <SlideBox>
                    <CustomSlick
                        {...settings}
                    >
                        {serviceData.map((item, index) => (
                            <SlideItems
                                key={`podo-${item.type}-${index}`}
                            >
                                <SlideImage src={`/img/home/img_podo_${item.type}.png`} />
                            </SlideItems>
                        ))}
                    </CustomSlick>
                </SlideBox>
            </Container>
        </SectionBackground>
    );
}

const SectionBackground = styled.div`
    background-color: ${colors.BlueGray300};
    padding-top: 121px;
    padding-bottom: 140px;
    width: 100%;
    overflow: hidden;
`;

const Container = styled.div`
    width: 1380px;
    padding: 0 30px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const SectionTitle = styled.div`
    margin-top: 55px;
    font-size: 40px;
    color: ${colors.Black200};
    line-height: 1.4;

    span {
        color: ${colors.Blue100};
        font-weight: 700;
    }
`;

const SectionSubTitle = styled.div`
    margin-top: 27px;
    font-size: 22px;
    font-weight: 400;
    line-height: 1.4;
    color: ${colors.BlueGray800};
`;

const LinkButton = styled.div`
    width: 174px;
    height: 58px;
    border: 1px solid ${colors.BlueGray700};
    background-color: ${colors.BlueGray400};
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.BlueGray800};
    font-size: 22px;
    font-weight: 400;
    margin-top: 160px;
    cursor: pointer;
`;

const TitleBox = styled.div`
    flex-basis: 294px;
`;

const SlideBox = styled.div`
    flex-basis: calc(100% - (294px + 156px));
    flex-shrink: 0;
    width: calc(100% - (294px + 156px));
    height: 406px;
    position: relative;    
`;

const CustomSlick = styled(Slider)`
    position: absolute;
    top: 0;
    left: 0;
    width: 1450px;
    height: 406px;

    .slick-dots {
        bottom: -55px;
        text-align: left;

        & li {
            margin: 0;
        }

        & li,
        & li button {
            width: 218px;
            height: 6px;
            overflow: hidden;

            &:before {
                display: none;
            }
        }

        & li button {
            background-color: ${colors.BlueGray400};
        }

        & li.slick-active,
        & li.slick-active button {
            width: 218px;
            height: 6px;
            overflow: hidden;

            &:before {
                display: none;
            }
        }
        
        & li.slick-active button {
            background-color: ${colors.BlueGray800};
        }
    }
`;

const SlideItems = styled.div`
    width: 423px;
    padding-right: 25px;
`;

const SlideImage = styled('img')`
    width: 423px;
`;

export default Services;
