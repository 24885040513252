import React from 'react';
import styled from '@emotion/styled/macro';
import { nl2br } from '@utils/help';
import { colors } from '@utils/ui_palette';

interface IHomeTitle {
    title: string;
}

function HomeTitle({ title } : IHomeTitle) {
    return (
        <Title dangerouslySetInnerHTML={{ __html: nl2br(title) }} />
    );
}

const Title = styled.div`
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: ${colors.Black100};
    position: relative;
    display: inline-block;
    z-index: 0;

    &:after {
        width: 8px;
        height: 8px;
        content: '';
        display: block;
        background-color: ${colors.Blue100};
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: calc(100% + 5px);
        z-index: 0;
    }
`;

export default HomeTitle;
