import React, { useState, useMemo, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import { ReactNotifications } from 'react-notifications-component';
import { transparentHeader } from '@utils/help';
import 'react-notifications-component/dist/theme.css';

// Components
import Header from '@components/layout/Header';
import Footer from '@components/layout/Footer';

function Layouts() {
    const [intro, setIntro] = useState<boolean>(true);
    const { pathname } = useLocation();
    const introExpire = Date.now() + (21600000 * 4);

    const getContentsHeight = useMemo(() => {
        if (transparentHeader(pathname)) {
            return 0;
        }

        return 80;
    }, [pathname]);

    useEffect(() => {
        const getModalExpire = localStorage.getItem('introExpire');

        if (!getModalExpire && Number(getModalExpire) < Date.now()) {
            setIntro(false);

            setTimeout(() => {
                setIntro(true);
                localStorage.setItem('introExpire', introExpire.toString());
            }, 1000);
        }
    }, []);

    return (
        <LayoutBlock>
            {intro && (
                <>
                    {/* HEADER 영역 */}
                    <Header />

                    {/* BODY 영역 */}
                    <BodySection height={getContentsHeight}>
                        <ReactNotifications />
                        <Outlet />
                    </BodySection>

                    {/* FOOTER 영역 */}
                    <Footer />
                </>
            )}

            {!intro && (
                <IntroBg>
                    <Logo />
                </IntroBg>
            )}
        </LayoutBlock>
    );
}

const LayoutBlock = styled.section`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;

const IntroBg = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    animation-name: sectionAni;
    animation-duration: 1s;

    @keyframes sectionAni {
        0% {
            opacity: 1;
        }
        80% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
`;

const Logo = styled.div`
    background-image: url('/img/layout/ic_intro_logo.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;;
    width: 379px;
    height: 82px;
`;

const BodySection = styled.div<{height: number}>`
    padding-top: ${(props) => (`${props.height}px`)};
`;

export default Layouts;
