export const colors = {
    White100: '#ffffff',

    BlueGray200: '#FCFCFC',
    BlueGray300: '#F9F9F9',
    BlueGray400: '#F3F3F3',
    BlueGray500: '#D9D9D9',
    BlueGray600: '#9E9E9E',
    BlueGray700: '#707070',
    BlueGray800: '#636363',

    Black100: '#333333', // base
    Black200: '#212121',
    Black300: '#000000',

    Brown100: '#372511',

    Red100: '#D71446',
    Red200: '#D71446',

    Blue100: '#1793FF',
    Blue200: '#050537',

    Green100: '#1996AF',
    Green200: '#1B7C3E',

    Orange100: '#F7941D',
};

export const fonts = {
    Tinos: '\'Tinos\', serif',
};

export const common = {
    headerHeight: '80px',
    bannerHeaderHeight: '124px',
    contents: '1280px',
};
